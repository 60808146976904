import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-stickyheader',
  templateUrl: './StickyHeader.component.html',
  styleUrls: ['./StickyHeader.component.scss']
})

export class StickyheaderComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }
}
