import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import emailjs from 'emailjs-com';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-contact-form-v2',
  templateUrl: './ContactFormV2.component.html',
  styleUrls: ['./ContactFormV2.component.scss']
})
export class ContactFormV2Component implements OnInit {

  public form: FormGroup;
  emailPattern: string = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$';

  success: any = null;

  constructor(
    private fb: FormBuilder,
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern(this.emailPattern)]],
      message: ['', Validators.required],
      phone: ['', Validators.required]
    });
  }

  submitForm() {
    const template_params = {
      'reply_to': 'info@tofuvegan.com',
      'name': this.form.controls['name'].value,
      'email': this.form.controls['email'].value,
      'phone': this.form.controls['phone'].value,
      'message': this.form.controls['message'].value
    };

    const user_id = 'user_jCTIgKxYBwlwYGfd2ibN0';
    const service_id = 'service_tofuvegan';
    const template_id = 'teahouse_contact';
    emailjs.send(service_id, template_id, template_params, user_id).then(
      () => {
        this.success = true;
      }
    );
  }

}
