import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-language',
  templateUrl: './Language.component.html',
  styleUrls: ['./Language.component.scss']
})
export class LanguageComponent implements OnInit {

  constructor(public translateService: TranslateService) {
  }

  ngOnInit() {
  }

  switchLanguage(language: any) {
    this.translateService.use(language);
  }
}
