import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Routes, RouterModule} from '@angular/router';

import {MainComponent} from './Main/Main.component';

export const AppRoutes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: '',
    component: MainComponent,
    children: [
      {
        path: 'home',
        loadChildren: () => import('./Pages/Home/Home.module').then(m => m.HomeModule)
      },
      {
        path: 'contact',
        loadChildren: () => import('./Pages/Contact/Contact.module').then(m => m.ContactModule)
      },
      {
        path: 'reservation',
        loadChildren: () => import('./Pages/Reservation/Reservation.module').then(m => m.ReservationModule)
      },
      {
        path: 'takeaway',
        loadChildren: () => import('./Pages/Takeaway/Takeaway.module').then(m => m.TakeawayModule)
      }
    ]
  }
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(AppRoutes)
  ],
  exports: [RouterModule],
  declarations: []
})
export class AppRoutingModule {
}
