import {Component, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-working-hours',
  templateUrl: './WorkingHours.component.html',
  styleUrls: ['./WorkingHours.component.scss']
})
export class WorkingHoursComponent implements OnInit {

  constructor(
    private translateService: TranslateService
  ) {
  }

  ngOnInit() {
  }

}
