import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-subscribe',
  templateUrl: './Subscribe.component.html',
  styleUrls: ['./Subscribe.component.scss']
})
export class SubscribeComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
